.card {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 400px;
  background-color: var(--color-main-alt);
  border-radius: 30px;
  transition: all 0.3s;
  color: var(--color-dark);
}

.card > div {
  margin-left: 25px;
  margin-right: 25px;
}

.spacer {
  display: flex;
  margin: 10px 0;
  justify-content: center;
  width: 100%;
}

.title {
  font-weight: 800;
  letter-spacing: 2px;
  font-size: 28px;
  text-align: center;
  margin: 20px 0 10px 0;
  color: var(--color-contrast);
}

.description {
  font-weight: 600;
  text-align: center;
  margin: 10px 0 15px 0;
  font-size: 18px;
}

.photo {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 30px;
  overflow: clip;
  height: 200px;
  width: 300px;
  margin: 15px 0 15px 0;
  box-shadow: 5px 5px 40px rgba(1, 1, 1, 0.5);
  background-color: var(--color-dark);
}

.noPhotoText {
  font-weight: 800;
  letter-spacing: 2px;
  font-size: 24px;
  text-align: center;
  margin: 20px 20px 10px 20px;
  color: var(--color-main-alt);
}

.photo * {
  position: relative;
  max-width: 300px;
}

.bulletPoints > div {
  margin: 20px 10px 0px 10px;
}

.bulletPoints > div::before {
  display: inline-block;
  content: " ";
  min-width: 10px;
  min-height: 10px;
  margin-right: 10px;
  border-radius: 50%;
  background-color: var(--color-dark);
}

.utilizesHolder {
  width: 95%;
  display: flex;
  justify-content: flex-start;
}

.utilizes {
  margin: 20px 25px 10px 25px;
  line-height: 1.3;
}

.utilizes > span {
  font-weight: 800;
}

.buttonsBackground {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  background-color: var(--color-dark);
  border-radius: 0 0 30px 30px;
}

.buttons {
  margin: 10px 25px 10px 25px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.buttons > div {
  margin: 5px 0 5px 0;
}
