.spacerHolder {
    display: flex;
    align-items: center;
    justify-content: center;
    width: inherit;
    height: inherit;
}

.spacer {
    content: " ";
}