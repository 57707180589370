.body {
    min-width: 200px;
    background-color: var(--color-main-alt);
    border-radius: 15px;
    padding: 20px 20px 10px 20px;
}

.navigation {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.dotHolder {
    display: flex;
    flex-direction: row;
    place-items: center;
}

.dot {
    background-color: var(--color-dark);
    width: 10px;
    height: 10px;
    border-radius: 50%;
    margin-left: 5px;
    margin-right: 5px;
    transition: all 0.3s;
}

.dot.dotActive {
    transform: scale(200%);
}

.swatches {
    margin-top: 20px;
}

.swatchGroup {
    width: 100%;
    height: 40px;
    display: flex;
    flex-direction: row;
    border-radius: 15px;
    overflow: clip;
    box-shadow: 2px 2px 5px rgba(1, 1, 1, 0.5);
    margin: 10px 0 10px 0;
    cursor: pointer;
    transition: all 0.3s;
}

.swatchGroup:hover {
    transform: translateX(5px);
}

.swatch {
    min-height: 40px;
    min-width: 40px;
}