.navbar {
    display: flex;
    flex-direction: row;
    margin: 20px 15px 20px 15px;
}

.navbar * {
    transition: all 0.3s;
    color: var(--color-contrast);
}

.name {
    font-weight: 900;
    font-size: 28px;
    line-height: 1.2;
    letter-spacing: 2px;
    text-decoration: none;
    transition: all 0.3s;
}

.name:hover {
    transform: scale(110%);
}

.name:active * {
    color: var(--color-main-alt);
}

@media screen and (min-width: 1200px) {
    .navbar {
        display: flex;
        flex-direction: row;
        margin: 20px 35px 20px 35px;
    }

    .name {
        font-size: 32px;
    }

}

.rightNav {
    justify-content: end;
    width: 100%;
    height: 100%;
    margin-right: 0;
    padding-right: 0;
    display: none;
}

@media screen and (min-width: 800px) {
    .rightNav {
        display: block;
    }
}

.buttons {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-bottom: 15px;
}

.buttons > * {
    margin: 0 10px 0 10px;
}

.lineHolder {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    height: 100%;
}

.line {
    background-color: var(--color-contrast);
    content: " ";
    height: 3px;
    width: 90%;
}

.colorSelector {
    display: none;
    position: absolute;
    right: 50px;
    top: 100px;
    background-color: var(--color-main-alt);
    border-radius: 15px;
    transition: all 0.3s;
    box-shadow: -5px 5px 40px rgba(1, 1, 1, 0.5);
}

@media screen and (min-width: 800px) {
    .colorSelector.colorSelectorEnabled {
        display: block;
    }
}

.mobileRightSide {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
}

.hamburger > div {
    padding: 20px;
}

@media screen and (min-width: 800px) {
    .mobileRightSide {
        display: none;
    }
}

.mobileNavLeftLine {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 50%;
    width: 60%;
    margin-right: 0;
    margin-left: 20px;
}

.mobileNavRightLine {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 50%;
    width: 10%;
    margin-left: 0;
}

.mobileLine {
    background-color: var(--color-contrast);
    content: " ";
    height: 3px;
    width: 100%;
}

.mobileMenu {
    position: absolute;
    width: 65%;
    height: auto;
    right: -150px;
    top: 15%;
    display: none;
    background-color: var(--color-main);
    padding: 20px 5px 20px 5px;
    border-radius: 20px;
    transition: all 1s;
    box-shadow: -5px 5px 40px rgba(1, 1, 1, 0.5);
    z-index: 101;
}

.mobileMenu.gradient {
    background: linear-gradient(120deg, var(--color-main) 0%, var(--color-secondary-gradient) 100%);
}

.mobileBackgroundBlur {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    display: none;
    overflow: hidden;
    transition: all 0.3s;
    z-index: 100;
}

.mobileBackgroundBlur.mobileMenuEnabled {
    position: absolute;
    display: block;
    backdrop-filter: blur(5px)
}

@media screen and (min-width: 800px) {
    .mobileBackgroundBlur.mobileMenuEnabled {
        display: none;
    }
}

.mobileMenu.mobileMenuEnabled {
    display: block;
    position: absolute;
    right: 35px;
}

@media screen and (min-width: 800px) {
    .mobileMenu.mobileMenuEnabled {
        display: none;
    }
}

.mobileMenuNavigation {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.mobileMenuLineHolder {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 50%;
    width: 100%;
    margin-right: 0;
    margin-left: 20px;
}

.mobileMenuLine {
    background-color: var(--color-contrast);
    content: " ";
    height: 3px;
    width: 100%;
}

.mobileMenuCloseButton > div {
    padding: 20px;
}

.mobileButtons {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.mobileButtons > a {
    padding: 10px 0 10px 0;
}

.mobileColorSelector {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
}
