.pageBody {
  color: var(--color-contrast);
  display: flex;
  flex-direction: column;
  place-items: center;
}

.intro {
  display: flex;
  flex-direction: column;
  margin-top: 75px;
  max-width: 1200px;
}

@media screen and (min-width: 800px) {
  .pageBody {
    margin: 0 50px 0 50px;
  }

  .intro {
    margin-top: 110px;
    margin-bottom: 50px;
    flex-direction: row;
  }

  .subInfo {
    display: flex;
    flex-direction: row;
  }
}

@media screen and (min-width: 1200px) {
  .intro {
    margin: 100px 0 25px 0;
  }
}

.decoration {
  display: flex;
  flex-direction: row;
  place-items: center;
  justify-content: center;
  margin: 50px 0 50px 0;
}

.hi {
  background-color: var(--color-light);
  color: var(--color-dark);
  display: flex;
  place-items: center;
  justify-content: center;
  text-align: center;
  position: relative;
  top: -50px;
  width: 35px;
  height: 35px;
  padding: 60px;
  border-radius: 40px;
  box-shadow: 5px 5px 10px rgba(1, 1, 1, 0.5);
  font-size: 60px;
  font-weight: 900;
}

.photo * {
  position: relative;
  left: -25px;
  border-radius: 50%;
  width: 150px;
  height: 150px;
  border: 10px solid var(--color-light);
  box-shadow: 5px 5px 40px rgba(1, 1, 1, 0.5);
  z-index: -1;
}

@media screen and (min-width: 1200px) {
  .decoration {
    margin: 0;
  }

  .photo * {
    width: 200px;
    height: 200px;
    border: 15px solid var(--color-light);
  }

  .hi {
    width: 100px;
    height: 100px;
    font-size: 100px;
    padding: 60px;
  }
}

.header {
  box-sizing: content-box;
  text-align: center;
  display: flex;
  justify-content: center;
  place-items: center;
}

.header h1 {
  font-size: 32px;
  font-weight: 500;
  letter-spacing: 1px;
  margin: 50px 25px 25px 25px;
}

.header h1 span {
  font-weight: 900;
}

.subline {
  max-width: 900px;
  margin: 50px 25px 25px 25px;
  text-align: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
}

@media screen and (min-width: 800px) {
  .header {
    padding-left: 25px;
  }

  .header h1 {
    font-size: 38px;
    margin: 0;
  }

  .subline {
    display: flex;
    display: flex;
    justify-content: center;
    place-items: center;
  }
}
.subline * {
  font-size: 24px;
  font-weight: 500;
  letter-spacing: 1px;
}

.subline * li {
  color: var(--color-light);
  margin: 5px 0px;
  text-align: left;
  letter-spacing: 1px;
}

.subline * span {
  font-weight: 900;
}

.mobileButtons {
  width: 100%;
}

.mobileButtons a {
  margin: 20px;
}

.spacer {
  width: 100%;
  margin-top: 10px;
}
