.beeHolder {
    position: fixed;
    width: 100%;
    height: 97%;
    z-index: -100;
    overflow: hidden;
}

.bee {
    top: -100px;
    right: 350px;
    position: fixed;
    height: 600px;
    color: var(--color-main-alt);
    scale: 180%;
    overflow: hidden;
    z-index: -100;
}

@media screen and (min-width: 800px) {
    .bee {
        top: 0;
        right: 500px;
    }
}

@media screen and (min-width: 1921px) {
    .beeHolder {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .bee {
        right: auto;
        margin-right: 200px;
    }
}
