@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700;800;900&display=swap');

:root {
    --color-main: black;
    --color-secondary-gradient: #DC419B;
    --color-main-alt: #FFC985;
    --color-contrast: #1C1C1C;
    --color-light: #DBDBDB;
    --color-dark: #1C1C1C;
    background-color: var(--color-main);
    color: black;

    --small-desktop-bp: 800px;
    --desktop-bp: 1200px;
}

:root.gradient {
    min-height: 100%;
    min-width: 100%;
    background: linear-gradient(120deg, var(--color-main) 0%, var(--color-secondary-gradient) 100%) fixed;
}

body {
    margin: 0;
    font-family: 'Montserrat', sans-serif;
    font-weight: 500;


    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

